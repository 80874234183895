<script>

import {mapState, mapActions} from 'vuex';

export default {
  name: "HeaderComponent",
  computed: {
    ...mapState(['wallet_address']), // 映射 state
    connected() {
      if (this.wallet_address) {
        return this.wallet_address.substr(0, 6) + '...' + this.wallet_address.substr(-4)
      } else {
        return 'Connect Wallet'
      }
    }
  },
  data() {
    return {
      isVisible: false,
      isMenuVisible: false,
    }
  },
  methods: {
    ...mapActions(['connect_wallet']), // 映射 actions
    onClickWallet: function () {
      this.isVisible = true;
    },
    onClickClose: function () {
      this.isVisible = false;
    },
    onClickMetamask: async function () {
      await this.connect_wallet('metamask');
      this.isVisible = false;
    },
    onClickOxk: async function () {
      await this.connect_wallet('okx');
      this.isVisible = false;
    },
    onClickGate: async function () {
      await this.connect_wallet('gate');
      this.isVisible = false;
    },
    onClickMenu: function () {
      this.isMenuVisible = !this.isMenuVisible
    }
  }
}
</script>

<template>
  <div>
    <div class="wap_nav wap">
      <div class="mobile">
        <div class="mobile-inner">
          <div class="mobile-inner-header">
            <div class="logo">
              <router-link to="/"><img src="images/logo2.png" alt=""></router-link>
            </div>
            <div class="mobile-inner-header-icon mobile-inner-header-icon-out" @click="onClickMenu">
              <span></span><span></span></div>
          </div>
          <div class="mobile-inner-nav" v-show="isMenuVisible">
            <ul>
              <li>
                <h2 class="h2tit">
                  <router-link to="/">Home</router-link>
                </h2>
              </li>
              <li>
                <h2 class="h2tit">
                  <router-link to="/launch">Launch</router-link>
                </h2>
              </li>
              <li>
                <h2 class="h2tit">
                  <router-link to="/airdrop">Airdrop</router-link>
                </h2>
              </li>
              <li>
                <h2 class="h2tit">
                  <router-link to="/ranking">Ranking</router-link>
                </h2>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="head">
      <div class="wrap1">
        <div class="logo pc">
          <router-link to="/">
            <img src="images/logo2.png" alt="" style="width: 80px;height: 80px">
          </router-link>
        </div>
        <div class="nav pc">
          <ul>
            <li>
              <h2 class="h2tit">
                <router-link to="/">Home</router-link>
              </h2>
            </li>
            <li>
              <h2 class="h2tit">
                <router-link to="/launch">Launch</router-link>
              </h2>
            </li>
            <li>
              <h2 class="h2tit">
                <router-link to="/airdrop">Airdrop</router-link>
              </h2>
            </li>
            <li>
              <h2 class="h2tit">
                <router-link to="/ranking">Ranking</router-link>
              </h2>
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="link1">
            <a href="https://t.me/Bump_officia" target="_blank"><img src="images/li1.png" alt=""></a>
            <a href="https://x.com/bump_base" target="_blank"><img src="images/li2.png" alt=""></a>
          </div>
          <div class="link2"><a href="#"><img src="images/li3.png" alt=""></a></div>
          <div class="link3">
            <a href="javascript:;" @click="onClickWallet">{{ connected }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="alertModel" v-show="isVisible">
      <div class="shadowMl"></div>
      <div class="center">
        <div class="content2">
          <div class="close" @click="onClickClose"></div>
          <div class="title">Choose wallet</div>
          <div class="link">
            <a href="javascript:;" @click="onClickOxk">
              <img src="images/ims1.png" alt="">
              <span>OKX WALLET</span>
            </a>
            <a href="javascript:;" @click="onClickMetamask">
              <img src="images/ims2.png" alt="">
              <span>METAMASK WALLET</span>
            </a>
            <a href="javascript:;" @click="onClickGate">
              <img src="images/ims3.png" alt="">
              <span>GATE WALLET</span>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>