<template>
  <div class="home">

    <HeaderComponent/>

    <div class="el-carousel-container">
      <el-carousel class="el-carousel">
        <el-carousel-item class="el-carousel-item" v-for="carousel in carousels">
          <img :src="carousel.imageUrl" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="index">
      <!--      <div class="section1">-->
      <!--        <div class="wrap">-->
      <!--          <div class="left">-->
      <!--            <div class="title1">welcome to</div>-->
      <!--            <div class="title2">Bump</div>-->
      <!--            <div class="title3">MEME Launchpad on BaseChain</div>-->
      <!--            <div class="more">-->
      <!--              <a href="#"><img src="images/more.png" alt=""></a>-->
      <!--            </div>-->
      <!--            <div class="more2"><a href="#">How it works</a></div>-->
      <!--          </div>-->
      <!--          <div class="right"><img src="images/pic1.png" alt=""></div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="section2">
        <div class="wrap">
          <div class="choose">
            <div class="left">
              <h2 class="h2tit">Launched Time</h2>
              <dl>
                <dd>Launched Time</dd>
                <dd>Trading Volume</dd>
                <dd>24H Price Increase</dd>
              </dl>
            </div>
            <div class="right">
              <input
                  type="text"
                  placeholder="Search for token"
                  class="words"
                  v-model="search_for_token"
                  @keyup.enter="onClickSearch"
              >
              <input
                  type="button"
                  value=""
                  class="send"
                  @click="onClickSearch">
            </div>
          </div>
          <div class="list">
            <!--            <div class="item" @click="toDetail"  v-for>-->
            <div class="item"
                 v-for="item in token_list"
                 :key="item.id"
                 @click="toDetail(item)">

              <div class="imgbox">
                <a href="javascript:;">
                  <img :src="item.iconUrl" alt="">
<!--                  <el-image  :src="item.iconUrl"  />-->
                </a>
              </div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: {{ short_address(item.creatorAddress) }}</span>
                  <span class="title2">{{ item.name }} ({{ item.symbol }})</span>
                  <span class="dec">{{ item.description }}</span>
                  <span class="per">
                Market Cap: <b>${{
                      (item.marketCap * this.eth_price).toFixed(3)
                    }} ({{ item.bondingCurveProgress.toFixed(3) }}%)</b>
              </span>
                  <span class="line">
              <i :style="{ width: item.bondingCurveProgress + '%' }"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <!--          <div class="pagesize">-->
          <!--            <a href="#" class="border">《</a>-->
          <!--            <a href="#" class="border"><</a>-->
          <!--            <a href="#" class="current">1</a>-->
          <!--            <a href="#">2</a>-->
          <!--            <a href="#">3</a>-->
          <!--            <span>...</span>-->
          <!--            <a href="#">6</a>-->
          <!--            <a href="#" class="border">></a>-->
          <!--            <a href="#" class="border">》</a>-->
          <!--          </div>-->

          <div class="pagesize">
            <a href="#" class="border" @click.prevent="changePage(1)" :class="{ disabled: page === 1 }">《</a>
            <a href="#" class="border" @click.prevent="changePage(page - 1)" :class="{ disabled: page === 1 }"><</a>

            <a href="#" v-for="n in totalPages"
               :key="n"
               :class="{ current: page === n }"
               @click.prevent="changePage(n)">
              {{ n }}
            </a>

            <span>...</span>
            <a href="#" @click.prevent="changePage(totalPages)">{{ totalPages }}</a>

            <a href="#" class="border" @click.prevent="changePage(page + 1)" :class="{ disabled: page === totalPages }">></a>
            <a href="#" class="border" @click.prevent="changePage(totalPages)"
               :class="{ disabled: page === totalPages }">》</a>
          </div>

        </div>
      </div>
      <div class="section3">
        <div class="wrap">
          <div class="title">Support</div>
          <div class="list">
            <div class="item">
              <div class="icon"><img src="images/cir1.png" alt=""></div>
              <div class="content">
                <div class="title">UniSwap</div>
                <div class="dec">Uniswap products are powered by the Uniswap Protocol. The protocol is the largest
                  onchain marketplace, with billions of dollars in weekly volume across thousands of tokens on Ethereum
                  and 7+ additional chains.
                </div>
              </div>
            </div>
            <div class="item">
              <div class="icon"><img src="images/cir2.png" alt=""></div>
              <div class="content">
                <div class="title">BASE</div>
                <div class="dec">Base is built as an Ethereum L2, with the security, stability, and scalability you need
                  to power your onchain apps. Confidently deploy any EVM codebase and onramp your users and assets from
                  Ethereum L1, Coinbase, and other interoperable chains.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="footer">
      <div class="wrap">
        <p>
          Disclaimer: Digital assets are highly speculative and involve significant risk of loss. The value of meme
          coins is extremely volatile, and any one who wishes to trade in any meme coin should be prepared for the
          possibility of losing their entire investment. BUMP makes no representations or warranties regarding the
          success or profitability of any meme coin developed on the platform. BUMP is a public, decentralized, and
          permissionless platform.
        </p>
        <p>
          Participation by any project should not be seen as an endorsement or recommendation by BUMP . Users should
          assess their financial situation, risk tolerance, and do their own research before trading in any meme coins
          on the platform. BUMP will not be held liable for any losses, damages, or issues that may arise from trading
          in any meme coins developed on the platform.
        </p>
      </div>
    </div>


  </div>
</template>

<style scoped>
.el-carousel {
  width: 100%;
  height: auto; /* 允许高度自适应 */
  aspect-ratio: 799 / 337; /* 保持宽高比 */
  margin-bottom: 16px;
}

.el-carousel-item {
  width: 100%;
  height: auto; /* 允许高度自适应 */
  aspect-ratio: 799 / 337; /* 保持宽高比 */
}

.el-carousel img {
  width: 100%; /* 确保图片宽度为100% */
  height: auto; /* 允许高度自适应 */
  object-fit: fill; /* 保持图片比例，裁剪多余部分 */
}
</style>

<script>
// @ is an alias to /src

import HeaderComponent from "@/components/HeaderComponent.vue";
import router from "@/router";
import axios from '../utils/axios';
import {short_address} from "@/utils/utils";
import {ElMessage} from "element-plus"; // 引入你创建的 Axios 实例

export default {
  name: 'HomeView',
  components: {
    HeaderComponent
  },
  data: function () {
    return {
      page: 1,
      pageSize: 8,
      token_list: [],
      eth_price: 0,
      totalPages: 0,
      carousels: [],
      search_for_token: ""
    }
  },
  methods: {
    short_address,
    toDetail(item) {
      router.push('/detail?token=' + item.tokenAddress)
    },
    async onClickSearch() {
      if (this.search_for_token) {
        const load_resp = await axios.get("/api/token/" + this.search_for_token);
        if (load_resp.data.code === 0 && load_resp.data.data.token != null) {
          await router.push('/detail?token=' + this.search_for_token)
        } else {
          ElMessage.error("Token not found")
        }
      }
    },
    async load() {
      const load_resp = await axios.post('/api/token_list', {
        page: this.page,
        pageSize: this.pageSize
      })
      if (load_resp.data.code === 0) {
        this.token_list = load_resp.data.data.tokens
        this.eth_price = load_resp.data.data.eth_price;
        this.totalPages = Math.ceil(load_resp.data.data.total / this.pageSize); // Calculate total pages
      }
      const load_carousel_resp = await axios.get('/api/carousel');
      if (load_carousel_resp.data.code === 0) {
        this.carousels = load_carousel_resp.data.data.carousels;
      }
    },
    changePage(newPage) {
      if (newPage < 1 || newPage > this.totalPages) {
        return; // Prevent going out of bounds
      }
      this.page = newPage;
      this.load(); // Reload the data for the new page
    }
  },
  mounted() {
    this.load()

    //从querystring中获取到invitor参数
    const invitor = new URLSearchParams(window.location.search).get("invitor");
    console.log("invitor", invitor)
    if (invitor) {
      //保存到localstore
      localStorage.setItem("invitor", invitor)
    }

  }
}
</script>
